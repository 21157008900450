import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/fbert/OneDrive/Documents/wwwBertramFamilyCom/GatsbyJS/src/components/layout-mdx.js";
import { TwoCol, Main, Aside } from "../../../../components/felix-mdx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TwoCol mdxType="TwoCol">
      <Main mdxType="Main">
        <h2>{`Early Days`}</h2>
        <p>{`When I first started playing with automated investment strategies in 2011, I
nicknamed the project PerlStock, as my first implementation was in Perl. It was
a fun project, with the following key features:`}</p>
        <ul>
          <li parentName="ul">{`download quotes from Yahoo! finance`}</li>
          <li parentName="ul">{`event-driven backtesting engine, calculating various indicators on daily bars`}</li>
          <li parentName="ul">{`portfolio-oriented algorithm, selecting 10 stocks from a universe of about 200 names, and weighting them to achieve performance and risk objectives`}</li>
          <li parentName="ul">{`reporting engine, exporting backtesting results to Excel`}</li>
        </ul>
        <h2>{`A New Venture`}</h2>
        <p>{`After promising simulations, it was time to prove the concepts in live trading
in 2012. The project has undergone several rewrites since, leaving not a single
line of code from the original project in place. However, the key concepts of
this first venture into day-trading remain valid to this day, leading to the
decision to start a new business with its own website in early 2018:
Say hello to `}<a parentName="p" {...{
            "href": "https://www.bertram.solutions"
          }}>{`Bertram Solutions`}</a>{`!`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/35b95d3da5f5603b0b813341d6e391ca/bf286/SectorPerf.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "62.66666666666667%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABhUlEQVQoz5WR227aQBCGef8XqHrRF2iUyyo3UUUUkYASCjU0LSc7xjgcjIPNsgfvzs5UaxNKSCO1v0ba03y7M/vX7BsholIqz/PtdssYk1KenB5UqwYiwr/pJLtKoxfVDve9ff94abK5NRrxv2C0lgjkziShJcJy/x9g6zAkgkJAOgM2BCgQyVrUhQEA1/O7MJHlmZl2MQmLPJTpt6pmtMDyjHNujHkPBtw87WbDVRSt0ySOH3rjFew/z3LOpJCHsgGPfSCC7ZqnyzD2g6g3CfrdUeL5SwAgIgOQ5bnWeg/TkQOoNTzPFvH01uu37q+9Xtt/jBbRIHlsuz6JrNHPacLYzsFENH5oPMUB32V+/6pd/9RsnF83zr93vnq9y373MhjcxIEXTn8aMCeeOzhbhV/OPtQvPnZanye/mv7gbhmNZZaKbL2Y/cg3c65ZYQu0f7C9VYiu7PnofjOfkkWQwipRenQkdFFyr2FRSmujtZFSKaVVoZWbSSmECylkeSBLVV9Vwb8BBx/sxOFgM+IAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Sector Performance",
                "title": "Sector Performance",
                "src": "/static/35b95d3da5f5603b0b813341d6e391ca/c1b63/SectorPerf.png",
                "srcSet": ["/static/35b95d3da5f5603b0b813341d6e391ca/5a46d/SectorPerf.png 300w", "/static/35b95d3da5f5603b0b813341d6e391ca/0a47e/SectorPerf.png 600w", "/static/35b95d3da5f5603b0b813341d6e391ca/c1b63/SectorPerf.png 1200w", "/static/35b95d3da5f5603b0b813341d6e391ca/bf286/SectorPerf.png 1688w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2>{`Yet Another Backtesting Engine`}</h2>
        <p>{`Later in 2018, and after being frustrated with the limitations commercial
backtesting engines have, I decided once more to roll my own. This time, it is
written in C# and named TuringTrader in admiration of
`}<a parentName="p" {...{
            "href": "https://en.wikipedia.org/wiki/Alan_Turing"
          }}>{`Alan Turing`}</a>{`.
A quick summary of TuringTrader’s key features:`}</p>
        <ul>
          <li parentName="ul">{`Features`}
            <ul parentName="li">
              <li parentName="ul">{`Simulate equity trades, portfolios of equities, and options`}</li>
              <li parentName="ul">{`Calculate indicators, with a growing library of standard indicators`}</li>
              <li parentName="ul">{`Simple Windows Desktop UI for interactive sessions`}</li>
              <li parentName="ul">{`Strong focus on easy-to-use time-series APIs, making coding a breeze`}</li>
              <li parentName="ul">{`Multi-threaded optimizer engine, able to utilize all available CPU cores`}</li>
            </ul>
          </li>
          <li parentName="ul">{`Data Inputs`}
            <ul parentName="li">
              <li parentName="ul">{`Import data in any CSV format with configurable column-mapping`}</li>
              <li parentName="ul">{`Automatic download/ update of data files from Norgate, IQFeed, Tiingo, Yahoo, and FRED`}</li>
              <li parentName="ul">{`Query account summary and positions from Interactive Brokers`}</li>
            </ul>
          </li>
          <li parentName="ul">{`Report Outputs`}
            <ul parentName="li">
              <li parentName="ul">{`Create beatiful native reports, based on customizable C# templates`}</li>
              <li parentName="ul">{`Create customized Excel reports with just a few lines of VBA`}</li>
              <li parentName="ul">{`Create customized R reports, either in straight R, or with R-Markdown`}</li>
            </ul>
          </li>
          <li parentName="ul">{`Status`}
            <ul parentName="li">
              <li parentName="ul">{`Production quality code, actively maintained, and tested`}</li>
              <li parentName="ul">{`Onlin API documentation, and quick start guide`}</li>
              <li parentName="ul">{`Demo algorithms to shorten the learning curve`}</li>
            </ul>
          </li>
        </ul>
        <p>{`I am quite confident in this project, and as of November 2018, pretty much all
of Bertram Solutions’ R&D runs on TuringTrader, most notably all of our Model Portfolios.`}</p>
        <h2>{`TuringTrader as a Service`}</h2>
        <p>{`TuringTrader has grown tremendously. What started out as a clunky tool for nerds,
has progressed to a consumer-friendly tool used by quantitative researchers and
technical traders around the world.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "768px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/acddf7096f7c37847e254b8885a48efd/e5715/turingtrader-world-768x492.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "64%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAACM0lEQVQ4y21T227TQBDNT/DEv/ATvPDMN9EnnmgqghRUKRB4aFVVXCRESxFQRBoSp25w7o696921d+1dH7SbxCQoRxqtZj0+M3NmtlaWJTZmyhIWTGlnRa6QF4X7ZrHv/N9qjsiszGKZ5qh3FujME2Q8QcKYCyy0hjEGcaZQaANuExqzU0xFuAHJclxNKM7vYmSpAOccQggXKKUEjEGaa1saZkIhYNL9tynG3tesEyTSZbycJDjzI3wMKDKlwBhzpBa5NlDagMocXpzilZ/g+DbBz2WKPsmQFQaFKVGzZPcPPuPxiYeXA4r6TYhTP4IfC8hUQCrlCL/OOd74BKcBQ71H8KxHcdin7rTW9AgCplDjucbh9RTN7gJPOxFeDxmu5gIXU+ZasBizDGfDGI0+Qb1PXXXNAUXDo3juURfvUel03NHwYesG9w4u8aj9GxeTpLr/vhBoe0u0b2P8mlFMI4LzPxQfAoLWIHbar0VcEVptLFq9EA8a13jyJViJbZOVBt9mDP0lcwN7HxCc+BGOukscDwhmXFYT/rc2a2ezW3GqMFpPsDQaQUQxDAlmEUUkJEYixx0R6IYcstA7O7lqee3o9fgnLMOLXoRPM+HasAnttDmlKI2pCKZcoRcJMJm7uIpw29yKGIO3foxuLCsdQ55hRLhLrtdxy6zAUS/GuzGvEu8lXKQFfoTp1jOzOpdQZuvplaXbTaI0xjx3C2/9vYT2HSeq2NHGkm7a2r1bwcplW/4LxhnfafHUiH0AAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "turingtrader world 768x492",
                "title": "turingtrader world 768x492",
                "src": "/static/acddf7096f7c37847e254b8885a48efd/e5715/turingtrader-world-768x492.png",
                "srcSet": ["/static/acddf7096f7c37847e254b8885a48efd/5a46d/turingtrader-world-768x492.png 300w", "/static/acddf7096f7c37847e254b8885a48efd/0a47e/turingtrader-world-768x492.png 600w", "/static/acddf7096f7c37847e254b8885a48efd/e5715/turingtrader-world-768x492.png 768w"],
                "sizes": "(max-width: 768px) 100vw, 768px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Encouraged by this success, I made yet another step towards consumer-friendliness.
In late 2019, I launched `}<a parentName="p" {...{
            "href": "https://www.turingtrader.com"
          }}>{`TuringTrader.com`}</a>{`.
This is a membership site offering the
following features:`}</p>
        <ul>
          <li parentName="ul">{`Portfolios`}
            <ul parentName="li">
              <li parentName="ul">{`basic, from well-regarded books and publications`}</li>
              <li parentName="ul">{`premium, based on our own research`}</li>
            </ul>
          </li>
          <li parentName="ul">{`Features`}
            <ul parentName="li">
              <li parentName="ul">{`daily updated charts, metrics, and target allocations`}</li>
              <li parentName="ul">{`customizable dashboard`}</li>
              <li parentName="ul">{`email subscriptions`}</li>
              <li parentName="ul">{`background information, reviews, technical articles`}</li>
            </ul>
          </li>
        </ul>
      </Main>
      <Aside mdxType="Aside">
        <p>{`Resources:`}</p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.bertram.solutions"
            }}>{`Bertram Solutions’ home page`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.turingtrader.org"
            }}>{`TuringTrader open-source project`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.turingtrader.com"
            }}>{`TuringTrader membership site`}</a></li>
        </ul>
      </Aside>
    </TwoCol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      